import React from 'react';
import styled from 'styled-components'

const Info = styled.p`
  text-align: center;
  color: #7B7B7B;
  line-height: 2;
`
const Title = styled.h1`
  font-size: 26px;
  text-align: center;
  padding: 3em 0 1em;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2em;
  span {
    font-size: 16px;
    color: #666;
    padding-left: 1em;
  }
`
const IconList = styled.ul`
  display: flex;
  text-align: center;
  padding: 4em 0;
  >li {
    flex: 1;
    svg {
      font-size: 26px;
    }
    p {
      margin: 2em 0;
    }
  }
`

function App() {
  return (
    <div className="g-width">
      <Title>联系我们<span>CONTACT US</span></Title>
      <Info>您好，如有任何问题请联系我们，欢迎提交任何关于我们的问题和建议，我们将尽快回复您。</Info>
      <Info>感谢您对我们的帮助，我们7*24小时竭诚为您服务。</Info>
      <IconList>
        <li>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-mail"></use>
            </svg>
            <p>zengqz@v1010.cn</p>
        </li>
        <li>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-location"></use>
            </svg>
            <p>浙江省杭州市滨江区建业路华业大厦2212室</p>
        </li>
        <li>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-phone"></use>
            </svg>
            <p>400-058-1802</p>
        </li>
      </IconList>
    </div>
  );
}

export default App;
