import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  background-color: rgb(250, 247, 250);
`
const LeftBar = styled.div`
  >div {
    width: 80%;
    margin: 0 auto;
    box-shadow: 0px 3px 12px 0px rgba(90,90,90,0.27);
    padding: 4em;
  }
  svg {
    color: #FF613A;
    margin-right: 1em;
  }
  p {
    font-size: 14px;
    margin-bottom: 1.5em;
  }
  .card-title {
    font-size: 24px;
    padding-bottom: .5em;
    color: #545354;
    font-weight: bold;
    border-bottom: 1px solid #545354;
  }
  input {
    width: 46%;
    font-size: 14px;
    padding: .3em;
    margin-bottom: 1em;
  }
  .first-input {
    margin-right: 8%;
  }
  textarea {
    width: 100%;
    margin-bottom: 1em;
    padding: .3em;
    font-size: 14px;

  }
  button {
    width: 100%;
    padding: 1em 0;
    font-size: 14px;
    background-image: linear-gradient(250deg,rgba(255,97,58,1),rgba(243,81,41,1));
    border: none;
    color: #fff;
  }
`
const RightBar = styled.div`
`

function TimeLine() {
  return (
    <Wrap className="g-flex g-min-width">
      <LeftBar>
        <div>
          <p className="card-title">留言</p>
          <p>
            <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-phone"></use>
            </svg>
            <span>400-058-1802</span>
          </p>
          <p>
            <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-mail"></use>
            </svg>
            <span>zengqz@v1010.cn</span>
          </p>
          <p>
            <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-location"></use>
            </svg>
            <span>浙江省杭州市滨江区建业路华业大厦2212室</span>
          </p>
          <form>
            <input className="first-input" type="text" name="" placeholder="请填写姓名" />
            <input type="text" name="" placeholder="请填写联系方式" />
            <textarea name="" cols="30" rows="10" placeholder="请填写内容" />
            <button>提交</button>
          </form>
        </div>
      </LeftBar>
      <RightBar>
        <img width="100%" src={require('../../../assets/home/location.jpg')} alt="公司大事记" />
      </RightBar>
    </Wrap>
  );
}

export default TimeLine;
